// PRODUCTION
const api = 'https://api.zyppys.in/';
const newApi = 'https://1psfb8czdi.execute-api.ap-south-1.amazonaws.com/prod/';
export const generateToken = 'https://apiauth.zyppys.in/oauth/token'
export const urlType = 'Production'

// CURRENT TEST
// const api = 'https://uat.zyppys.in/';//CURRENT TEST
// const newApi = 'https://t3zqbkel30.execute-api.us-east-1.amazonaws.com/dev/'
// export const generateToken = 'https://uatauthnew.zyppys.in/oauth/token'
// export const urlType = 'Test'

export const urls = {
  locationByLatLongUrl: api + 'fetchLocationByLatLong',
  chauffeurCategories: api + 'fetchVehicleCategories',
  selfDriveCategories: api + 'fetchVehicleCategoriesForSelfDrive',
  vehiclesUrl: api + 'fetchVehicleDetails',
  vehiclesPricing: api + 'fetchVehiclePricing',
  coupons: api + 'fetchCoupons',
  profile: api + 'fetchProfile',
  tripStatusUrl: api + 'fetchTripStatus',
  updateProfile: api+ 'updateProfile',
  lastTripRating: api+ 'fetchLastTripRating',
  fetchOrUpdateRating: api+ 'fetchOrUpdateRating',
  myChoizePricing: api+ 'fetchMyChoizePricing',
  // hotelsUrl: api + 'fetchHotelDetails',
  // recreationsUrl: api + 'fetchRecreationDetails',
  // onRoutePlacesUrl: api + 'fetchOnRoutePlaces',
  sendOtpUrl: api + 'sendOTP',
  authOtpUrl: api + 'authenticateOTP',
  submitUrl: api + 'submitBidDetails',
  cancelUrl: api + 'cancelRide',
  // loadBidsForCustomer: api + 'loadBidsForCustomer',
  // tagsUrl: api + 'fetchRecreationTags',
  fetchTripsUrl: api + 'fetchTripDetailsForCustomer',
  updateScratchAmountProfileUrl: api + 'addScratchPoints',
  logMessage: api + 'logMessage',
  logTransaction: api + 'logTransaction',
  ip:'https://api.ipify.org?format=json',
  sendEmail: newApi + 'sendEmail',
  fetchMyChoizeLocationAndActivityList: api + 'fetchMyChoizeLocationAndActivityList',
  blockMyChoizeVehicle: api + 'blockMyChoizeVehicle',
  fetchStaticCities: api + 'fetchStaticCities',
  insertPlaceHolderOrder: api + 'insertPlaceHolderOrder',
  confirmPlaceHolderOrder: api + 'confirmPlaceHolderOrder',
  sendOTPPortal: api + 'sendOTPPortal',
  verifyCaptcha: api + 'verifyCaptcha',
  fetchZoomcarLocations: api + 'fetchZoomcarLocations',
  cancelInitiate: api + 'cancelInitiate'
}
